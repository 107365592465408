import React from "react";
import Case from "../../Components/Case";
import Clients from "../../Components/Clients";
import Customer from "../../Components/Customer";
import Footer from "../../Components/Footer";
import Leading from "../../Components/Leading";
import NavBar from "../../Components/Nav";
import Offer from "../../Components/offer";
import Sec1 from "../../Components/Sec1";

const Home = () => {
  return (
    <div>
      <NavBar />
      <Sec1 />
      <Leading />
      <Offer />
      <Case />
      <Customer />
      <Clients />
      <Footer />
    </div>
  );
};

export default Home;
