import React from "react";
import { Col, Row } from "react-bootstrap";
import "./case.css";
import Arrow from "../../assets/icons/arrow.png";
const Case = () => {
  return (
    <div className="case" id="case_studies">
      <h1 className="green-color text-center mt-5 mb-3">Case Studies</h1>
      <Row>
        <Col md={6} lg={4} xs={12} className="cs-item cs1">
          <h2>How we increased margin of a retail company 125% in 6 months</h2>

          <button className="rbn">
            Read More
            <br />
            <img src={Arrow} width={30} height={10} />
          </button>
        </Col>
        <Col md={6} lg={4} xs={12} className="cs-item cs2">
          <h2>Feasibility Study of starting apparel chain in tier || cities</h2>
          <div className="hbn">
            <button className="rbn">
              Read More
              <br />
              <img src={Arrow} width={30} height={10} />
            </button>
          </div>
        </Col>
        <Col md={6} lg={4} xs={12} className="cs-item cs3">
          <h2>How Meta reduced debt by generating passive income</h2>{" "}
          <div className="hbn">
            <button className="rbn">
              Read More
              <br />
              <img src={Arrow} width={30} height={10} />
            </button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6} lg={4} xs={12} className="cs-item cs1">
          <h2>How we increased margin of a retail company 125% in 6 months</h2>{" "}
          <div className="hbn">
            <button className="rbn">
              Read More
              <br />
              <img src={Arrow} width={30} height={10} />
            </button>
          </div>
        </Col>
        <Col md={6} lg={4} xs={12} className="cs-item cs2">
          <h2>Feasibility Study of starting apparel chain in tier || cities</h2>{" "}
          <div className="hbn">
            <button className="rbn">
              Read More
              <br />
              <img src={Arrow} width={30} height={10} />
            </button>
          </div>
        </Col>
        <Col md={6} lg={4} xs={12} className="cs-item cs3">
          <h2>How Meta reduced debt by generating passive income</h2>{" "}
          <div className="hbn">
            <button className="rbn">
              Read More
              <br />
              <img src={Arrow} width={30} height={10} />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Case;
