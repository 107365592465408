import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ClientImg from "../../assets/client.png";
import "./customer.css";
import { ReactComponent as Quote } from "../../assets/icons/quote.svg";
import CSImg from "../../assets/customers/c1.png";
import { Zoom } from "react-reveal";

const Customer = () => {
  return (
    <Container fluid className="bg-blue p-5">
      <h1 className="text-center white-color mb-2">What Our Customer Says</h1>
      <Container>
        <Row>
          <Col lg={4} md={4} xs={12}>
            <Zoom>
              <div className="cs-card">
                <Quote />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.1500s, when an unknown printer took a galley.
                </p>
                <Row>
                  <Col lg={2}></Col>
                  <Col lg={2}>
                    <center>
                      <img src={CSImg} />
                    </center>
                  </Col>
                  <Col lg={5}>
                    <h6 className="text-center">John Deo</h6>
                    <p className="text-center">CEO - Meta Inc</p>
                  </Col>
                  <Col lg={2}></Col>
                </Row>
              </div>
            </Zoom>
          </Col>
          <Col lg={4} md={4} xs={12}>
            <Zoom>
              <div className="cs-card">
                <Quote />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.1500s, when an unknown printer took a galley.
                </p>
                <Row>
                  <Col lg={2}></Col>
                  <Col lg={2}>
                    <center>
                      <img src={CSImg} />
                    </center>
                  </Col>
                  <Col lg={5}>
                    <h6 className="text-center">John Deo</h6>
                    <p className="text-center">CEO - Meta Inc</p>
                  </Col>
                  <Col lg={2}></Col>
                </Row>
              </div>
            </Zoom>
          </Col>
          <Col lg={4} md={4} xs={12}>
            <Zoom>
              <div className="cs-card">
                <Quote />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.1500s, when an unknown printer took a galley.
                </p>
                <Row>
                  <Col lg={2}></Col>
                  <Col lg={2}>
                    <center>
                      <img src={CSImg} />
                    </center>
                  </Col>
                  <Col lg={5}>
                    <h6 className="text-center">John Deo</h6>
                    <p className="text-center">CEO - Meta Inc</p>
                  </Col>
                  <Col lg={2}></Col>
                </Row>
              </div>
            </Zoom>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Customer;
