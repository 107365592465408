import { Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import Image from "../../assets/lead.png";
import "./lead.css";
import Logo from "../../assets/safe logo.png";
import Logo2 from "../../assets/logo2.png";
import ISTLogo from "../../assets/ist.png";
import Year from "../../assets/2004.png";
import Arr1 from "../../assets/arrow1.png";
import Arr2 from "../../assets/arrow2.png";
import { BrowserView, MobileOnlyView, MobileView } from "react-device-detect";

const Leading = () => {
  return (
    <Container fluid className="mt-3" id="about">
      <Fade up>
        <BrowserView>
          <div className="bg-grey w-100 p-3">
            <Row>
              <Col lg={4} md={4} xs={12}>
                <center>
                  <img src={Image} className="w-75 mt-3" />
                  <a href="https://www.awadubai.com/">
                    <div className="lg w-75">
                      <img src={Logo2} width="40%" className=" mt-1" />
                    </div>
                  </a>
                  <Row>
                    <Col></Col>
                    <Col lg={3} xs={3}>
                      <img src={Arr1} className="w-100 mt-3" />
                    </Col>
                    <Col lg={3}>
                      <img src={Year} className="w-100 mt-1" />
                    </Col>
                    <Col lg={3} xs={3}>
                      <img src={Arr2} className="w-100 mt-3" />
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col>
                      <a href="/">
                        <div className="lg1">
                          <img src={Logo} width="85%" className=" mt-1" />
                        </div>
                      </a>
                    </Col>
                    <Col>
                      <a href="https://innovativesafeme.com/">
                        <div className="lg2">
                          <img src={ISTLogo} width="85%" className=" mt-1" />
                        </div>
                      </a>
                    </Col>
                  </Row>
                </center>
              </Col>
              <Col md={8} lg={8} xs={12}>
                <h3 className="mb-5 mt-5">
                  <b>
                    {/* Leading In The Industry with 18+ years Of Professional
                Experience */}
                    About Us
                  </b>
                </h3>
                <p className="mt-1">
                  Safe Choice Consultancy LLC provides professional consultancy
                  services in business domains like Project Management, Finance
                  Management, Information Technology & Marketing Management.
                </p>
                <p className="mt-1">
                  Safe Choice Consultancy is under the umbrella of its parent
                  company Al Wasl, established in 2004, providing various
                  professional services in the field of Accounting, Auditing and
                  Business strategies through various process within an
                  organization with more than 400 satisfied clients from
                  different business spectrums.
                </p>

                <p className="mt-1">
                  When working towards the company’s objectives, every
                  organization faces a wide range of internal and external risk
                  factors. How to manage or mitigate such risk? We, Safe Choice
                  Consultancy can support you in identifying a significant
                  weakness in your internal management controls by implementing
                  suitable strategies and policies with effective resource
                  management and planning.
                </p>
                <p className="mt-1">
                  No matter how difficult the challenge or how long the process,
                  we thoroughly examine our client’s operations to enable them
                  to achieve their goals, based on our extensive experience and
                  knowledge accumulated through serving clients operating in
                  every business sector.
                </p>
              </Col>
            </Row>
          </div>
        </BrowserView>
        <MobileOnlyView>
          <div className="bg-grey w-100 p-3">
            <Row>
              <Col lg={4} md={4} xs={12}>
                <center>
                  <img src={Image} className="w-75 mt-3" />
                </center>
              </Col>
              <Col md={8} lg={8} xs={12}>
                <h3 className="mb-5 mt-5">
                  <b>
                    {/* Leading In The Industry with 18+ years Of Professional
                Experience */}
                    About Us
                  </b>
                </h3>
                <p className="mt-1">
                  Safe Choice Consultancy LLC provides professional consultancy
                  services in business domains like Project Management, Finance
                  Management, Information Technology & Marketing Management.
                </p>
                <p className="mt-1">
                  Safe Choice Consultancy is under the umbrella of its parent
                  company Al Wasl, established in 2004, providing various
                  professional services in the field of Accounting, Auditing and
                  Business strategies through various process within an
                  organization with more than 400 satisfied clients from
                  different business spectrums.
                </p>

                <p className="mt-1">
                  When working towards the company’s objectives, every
                  organization faces a wide range of internal and external risk
                  factors. How to manage or mitigate such risk? We, Safe Choice
                  Consultancy can support you in identifying a significant
                  weakness in your internal management controls by implementing
                  suitable strategies and policies with effective resource
                  management and planning.
                </p>
                <p className="mt-1">
                  No matter how difficult the challenge or how long the process,
                  we thoroughly examine our client’s operations to enable them
                  to achieve their goals, based on our extensive experience and
                  knowledge accumulated through serving clients operating in
                  every business sector.
                </p>
              </Col>
            </Row>
            <center>
              <a href="https://www.awadubai.com/">
                <div className="lg w-75">
                  <img src={Logo2} width="60%" className=" mt-1" />
                </div>
              </a>
              <Row>
                <Col></Col>
                <Col lg={3} xs={3}>
                  <img src={Arr1} className="w-100 mt-3" />
                </Col>
                <Col xs={3}>
                  <center>
                    <img src={Year} className="w-100 mt-1" />
                  </center>
                </Col>
                <Col lg={3} xs={3}>
                  <img src={Arr2} className="w-100 mt-3" />
                </Col>
                <Col></Col>
              </Row>
              <Row>
                <Col>
                  <a href="/">
                    <div className="lg1">
                      <img src={Logo} className="w-100 mt-1" />
                    </div>
                  </a>
                </Col>
                <Col>
                  <a href="https://innovativesafeme.com/">
                    <div className="lg2">
                      <img src={ISTLogo} className="w-100 mt-1" />
                    </div>
                  </a>
                </Col>
              </Row>
            </center>
          </div>
        </MobileOnlyView>
        <Row className="mt-5">
          <Col className="border-grey" md={6} lg={6} xs={12}>
            <h4>Vision</h4>

            <p>To be recognized as the best and trusted partner.</p>
          </Col>
          <Col className="border-grey" md={6} lg={6} xs={12}>
            <h4>Mission</h4>

            <p>
              To provide the highest level of efficient, cost effective
              personalized professional services.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="border-grey" md={6} lg={6} xs={12}>
            <h4>Values</h4>
            <p>
              Quality & Safety, Innovation, and commitment to customers. We
              exhibit a strong will to win in the marketplace and in every
              aspect of business.
            </p>
          </Col>
          <Col className="border-grey" md={6} lg={6} xs={12}>
            <h4>Philosophy</h4>
            <p>
              The philosophy includes Agility, Integrity, Passion, and Service
              Excellence which are ingrained in our day-to-day operations. These
              virtues help us to become ‘Trusted business partner’ of our
              clients.
            </p>
          </Col>
        </Row>
      </Fade>
    </Container>
  );
};

export default Leading;
