import { Col, Container, Row } from "react-bootstrap";
import { ReactComponent as Investment } from "../../assets/investment.svg";
import { ReactComponent as Marketing } from "../../assets/marketing.svg";
import { ReactComponent as Sourcing } from "../../assets/sourcing.svg";
import { ReactComponent as Finance } from "../../assets/finance.svg";
import { ReactComponent as Project } from "../../assets/project_management.svg";
import "./offer.css";
import { Fade } from "react-reveal";
const Offer = () => {
  const OfCard = (props) => {
    return (
      <div className="o-card">
        <Row>
          <Col lg={4}>
            <props.img width={"100%"} />
            <h5>{props.head}</h5>
          </Col>
          <Col lg={8}>
            <p>{props.content}</p>
            {props.shead != null ? (
              <div>
                <p>{props.shead}</p>
                <ul>
                  <li>{props.l1}</li>
                  <li>{props.l2}</li>
                  {props.l3 != null ? <li>{props.l3}</li> : null}
                  {props.l4 != null ? <li>{props.l4}</li> : null}
                  {props.l5 != null ? <li>{props.l5}</li> : null}
                  {props.l6 != null ? <li>{props.l6}</li> : null}
                  {props.l7 != null ? <li>{props.l7}</li> : null}
                </ul>
              </div>
            ) : null}
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <Container className="mt-3 p-5" id="services">
      <h1 className="green-color text-center">What We Offer</h1>
      <Row>
        <Col lg={6} md={6} xs={12}>
          <Fade left>
            <OfCard
              img={Finance}
              head={"Project Management"}
              shead={"Our verticals includes :"}
              l1={"Business re-engineering, re-designing and re-structuring."}
              l2={"Setting up Standard Operating Procedures ( SOP)."}
              l3={"Financial Analysis, Planning and Management"}
              l4={"Investment Strategy & Pitching"}
              content={
                "We offer several services in Project Management domain based on the client’s specific needs and goals. From our expertise, we offer personalized advice to build organisational wealth like Project Management & financial planning, identify wellsuited investments and guideline to maintain an effective banking and investment portfolio."
              }
            />
            {/* <OfCard
              img={Investment}
              head={"Investment Consultancy"}
              shead={"Our Investment Consultation elements include :"}
              l1={"Investment strategy."}
              l2={"Business & Financial analysis."}
              l3={"Pitch investors."}
              content={
                "Where to invest or searching for the right investment plan is a challenging matter. We have curated it intending to guide, plan and strategize your hard-earned money in the best investment. Our Team of experts from financial work, banking and investment firm will guide in maintaining the portfolio."
              }
            /> */}

            <OfCard
              img={Marketing}
              head={"Marketing Management"}
              shead={"Includes :"}
              l1={"Advice on planning and pricing."}
              l2={"Market research."}
              l3={"Business promotion."}
              l5={"Digital Marketing strategies."}
              content={
                "Whether products or services, we strategize, place it in market and make you a brand."
              }
            />
          </Fade>
        </Col>
        <Col lg={6} md={6} xs={12}>
          <br />
          <br />
          <br />
          <br />
          <Fade right>
            <OfCard
              img={Project}
              // head={"Project Management Consultancy"}
              head={"Information Technology Consultation"}
              shead={"Constituents: :"}
              l1={"Strategic Planning & MIS."}
              l2={"Governance & Re-structuring."}
              l3={"Digital Transformation & Deployment."}
              l4={"Innovative Technology Adoption."}
              l5={"PMO & Project Management."}
              l6={"Quality and Service Management."}
              l7={"Risk Management."}
              content={
                "Every business has USPs and goals which they intend to achieve. At Safe, we make maintain it. From technology to strategy planning, by our expert opinion for practical solutions to address various issues, assist you with strategies and direct you to achieve your ultimate goals."
              }
            />
            <OfCard
              img={Sourcing}
              head={"Sourcing & Procurement Consultancy"}
              shead={"Our Sourcing & Procurement Consultation ABCs include:"}
              l1={"Row material & Retail goods sourcing."}
              l2={"Import & Export documentations."}
              content={
                "Purchase analysis to determine where to achieve savings, point out the system defects to overcome, so as you minimize the cost, determine the purchases specifications and tender procedure, advice on purchasing sources by rendering to the best standards as might involve E-commerce. We provide advice and assistance on purchasing retail goods also."
              }
            />
          </Fade>
        </Col>
      </Row>
    </Container>
  );
};

export default Offer;
