import React, { useRef, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import "./footer.css";
import Logo from "../../assets/safe logo.png";

import Logo2 from "../../assets/logo2.png";
import ISTLogo from "../../assets/ist.png";
import { ReactComponent as FB } from "../../assets/icons/facebook-round.svg";
import { ReactComponent as TW } from "../../assets/icons/twitter-round.svg";
import { ReactComponent as LI } from "../../assets/icons/linkedin-round.svg";
import { ReactComponent as CSvg } from "../../assets/contact.svg";

import { ReactComponent as Home } from "../../assets/office.svg";
import { ReactComponent as Phone } from "../../assets/phone.svg";
import { ReactComponent as Mail } from "../../assets/mail.svg";

import CImg from "../../assets/contact.png";
import { Fade } from "react-reveal";

const Footer = () => {
  const form = useRef();
  const [load, setLoad] = useState(false);
  const [sent, setSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoad(true);
    const data = new FormData(e.target);
    const name = data.get("name");
    const email = data.get("email");
    const message = data.get("message");
    const phone = data.get("phone");

    console.log(name);

    if (name != "" && email != "" && message != "") {
      emailjs
        .sendForm(
          "service_t48qnnq",
          "template_yg11k0c",
          form.current,
          "46kvOIPTCAsdlOx8w"
        )
        .then(
          (result) => {
            setSent(true);
            setLoad(false);
            document.getElementById("form").reset();
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      setLoad(false);
      alert("Please fill out all details !");
    }
  };

  return (
    <div className="footer p-5">
      <Container>
        <h1 className="white-color text-center">Get In Touch</h1>
        <h6 className="white-color text-center mb-5 mt-3">
          Feel Free To Discuss With Us
        </h6>
        <Row>
          <Col md={3} lg={3} xs={12}></Col>
          <Col md={6} lg={6} xs={12}>
            <Fade up>
              <div className="form-card" id="contact">
                {sent == false ? (
                  <Form ref={form} onSubmit={sendEmail} id="form">
                    <Row>
                      <Col xs={12} lg={6} md={6}>
                        <input
                          type={"text"}
                          className="form-control"
                          placeholder="type your name.."
                          name="name"
                        />
                        <input
                          type={"email"}
                          className="form-control mt-2"
                          placeholder="type your email.."
                          name="email"
                        />
                        <input
                          type={"text"}
                          className="form-control mt-2 mb-2"
                          placeholder="type your phone.."
                          name="phone"
                        />
                      </Col>
                      <Col>
                        <textarea
                          className="form-control mt-0"
                          placeholder="type your message.."
                          name="message"
                          rows={5}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {load == true ? (
                          <Spinner
                            className="m-2"
                            animation="border"
                            variant="primary"
                          />
                        ) : (
                          <button
                            type="submit"
                            style={{
                              backgroundColor: "#0097C4",
                              border: "none",
                              paddingRight: 20,
                              paddingLeft: 20,
                              color: "#00A651",
                              borderRadius: 15,
                              marginBottom: 30,
                              color: "#fff",
                            }}
                            className="mt-5  "
                          >
                            submit
                          </button>
                        )}
                      </Col>
                      <Col>
                        <CSvg />
                      </Col>
                    </Row>
                  </Form>
                ) : (
                  <Fade>
                    <div>
                      <h3 className="m-5 blue-color">
                        Our Office will get back to you soon..
                      </h3>
                      <Row>
                        <Col></Col>
                        <Col>
                          <CSvg />
                        </Col>
                      </Row>
                    </div>
                  </Fade>
                )}
              </div>
            </Fade>
          </Col>
          <Col md={3} lg={3} xs={12}></Col>
        </Row>
        <Row className="mt-5">
          <Col md={4} lg={4} xs={12}>
            <h6 className="white-color">Our Other Ventures</h6>
            <div className="white-line w-50 mb-4" />
            <a href="https://innovativesafeme.com/">
              <img src={ISTLogo} width={200} />
            </a>
            <br />
            <br />
            <a href="https://www.awadubai.com/">
              <img src={Logo2} width={200} />
            </a>
          </Col>
          <Col md={4} lg={4} xs={12}>
            <h6 className="white-color">More Info</h6>
            <div className="white-line" />
            <Row className="mt-1">
              <Col lg={1} xs={2}>
                <Home />
              </Col>
              <Col lg={11} xs={10}>
                <h6 className="white-color">
                  Safe Choice Consultancy LLC
                  <br />
                  P.O.Box 46271, Ofc . No. 403, 4th Floor <br /> Al Qusais Plaza
                  Damascus Street <br /> Al Qusais, Dubai, UAE.
                </h6>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col lg={1} xs={2}>
                <Mail />
              </Col>
              <Col lg={11} xs={10}>
                <h6 className="white-color">
                  info@safechoiceconsultancy.com  
                </h6>
              </Col>
            </Row>
            <Row className="mt-1 mb-1">
              <Col lg={1} xs={2}>
                <Phone />
              </Col>
              <Col lg={11} xs={10}>
                <h6 className="white-color">
                  +971 4 2838 480 &nbsp; +971 4 5915288
                </h6>
              </Col>
            </Row>
          </Col>
          <Col></Col>
          <Col md={3} lg={3} xs={12}>
            <img src={Logo} width={200} />

            {/* <h6 className="white-color text-center">Social Media</h6> */}
            <div className="white-line w-100" />
            <Row className="mt-4">
              <Col>
                <center>
                  <a href="https://www.linkedin.com/company/safechoiceconsultancy/">
                    <LI />
                  </a>
                </center>
              </Col>
              <Col>
                <center>
                  <TW />
                </center>
              </Col>
              <Col>
                <center>
                  <FB />
                </center>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Footer;
