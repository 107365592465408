import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ClientImg1 from "../../assets/clients/Group1.png";
import ClientImg2 from "../../assets/clients/Group2.png";
const Clients = () => {
  return (
    <Container fluid className="cli p-3 bg-dark">
      <h1 className="text-center white-color mt-3">Clients</h1>
      <Container>
        <Row>
          <Col></Col>
          <Col md={6} lg={6} xs={12} className="mt-5">
            <img src={ClientImg1} className="w-100" />
          </Col>
          <Col lg={4} md={4} xs={12} className="mt-5 mb-5">
            <center>
              <img src={ClientImg2} width={"90%"} />
            </center>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Clients;
