import { Col, Container, Row } from "react-bootstrap";
import Image from "../../assets/mainimg.png";
import "./sec1.css";
import { Fade, Zoom } from "react-reveal";
const Sec1 = () => {
  return (
    <Container id="home">
      <Row>
        <Col md={5} lg={5} xs={12} className="p-5">
          <Fade down>
            <h1 className="mt-5 mb-5">
              <b>
                Bring out the magic in <br />
                your business
              </b>
            </h1>
            {/* <p className="mt-4 justify">
              Over the past two decades since 2004, Al Wasl Accounting, the
              parent company provides business management strategies through
              various process within an organization with more than 400
              satisfied clients from different business spectrums. Safe choice
              consultancy LLC and Innovative Safe Technical Service LLC are
              under the umbrella of Al Wasl. Safe Choice Consultancy LLC can
              provide consultancy services in various aspects of business
              domains like Project Management, Finance Management, Information
              Technology & Marketing Management, and Innovative Safe Technical
              Service LLC can provide all type of technical and civil related
              works.
            </p> */}
            <a
              className="lets"
              href="/#contact"
              style={{ textDecoration: "none", }}
            >
              Let's Talk
            </a>
          </Fade>
        </Col>

        <Col lg={7} md={7} xs={12}>
          <Fade right>
            <center>
              <img src={Image} className="w-100 mt-3" />
            </center>
          </Fade>
        </Col>
      </Row>
    </Container>
  );
};

export default Sec1;
